.category-page {

    .down-arrow {
        margin-bottom: 10px;
    }
    .category-nav {
        margin-bottom: 2px;
    }

    #mast {
        height: 360px;
        margin-bottom: 0;
    }

    #featured-category {

        #become-a-dealer {
            h2 {
                padding-left: 0;
            }

            .border-row {
                margin-top: 25px;
                padding-top: 30px;
                border-top: 1px solid $theme-color;
                margin-left: 15px;
                margin-right: 15px;
            }

            .become-a-dealer-header {
                margin-bottom: 15px;
                font-size: 28px;
                color: #555555;

                .red-button {
                    font-size: 18px;
                    margin-top: 25px;
                }

                span {
                    font-size: 38px;
                    color: $theme-color;
                }
            }

            .become-a-dealer-info {
                p {
                    padding-left: 25px;
                }
                color: #555555;

                h3 {
                    padding-left: 25px;
                    margin-bottom: 10px;
                    margin-top: 25px;
                }
                ul {
                    color: #555555;
                    font-size: 16px;
                    list-style: none;
                    padding-left: 25px;
                    margin-bottom: 15px;
                }

                li {
                    margin-bottom: 5px;
                    padding-left: 15px;
                }

                li:before {
                    content: "\002022";
                    padding-right: 5px;
                    color: $theme-color;
                    margin-left: -15px;
                }
            }

            .become-a-dealer-form {
                padding-left: 15px;
                padding-right: 15px;

                .section-header {
                    color: $theme-color;
                    margin-bottom: 35px;
                }

                form {
                    .form-group {
                        padding-left: 25px;
                        padding-right: 25px;
                    }

                    label {
                        color: $theme-color;
                    }

                    .theme-button {
                        font-size: 16px;
                        height: 40px;
                        width: 230px;
                        padding-top: 0;
                        margin-left: 25px;
                    }

                    .form-control {
                        border: 1px solid $theme-accent;
                    }
                }

            }
        }


        h2 {
            padding-left: 15px;
            color: #555555;
            font-weight: bold;
            font-size: 18px;

            span {
                font-size: 30px;
                color: $theme-color;
            }
        }

        h3 {
            margin-top: 0;
            margin-bottom: 2px;
            color: $theme-color;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .theme-button {
        height: 50px;
        padding-top: 11px;
        font-size: 18px;
    }

    .featured-dealers {
        .row {
            margin-top: 25px;
            border-bottom: 1px solid $theme-color;
        }

        .featured-dealer-left {
            background: -webkit-linear-gradient(#eaeaea, #f8f8f8); /* For Safari 5.1 to 6.0 */
            background: -o-linear-gradient(#eaeaea, #f8f8f8); /* For Opera 11.1 to 12.0 */
            background: -moz-linear-gradient(#eaeaea, #f8f8f8); /* For Firefox 3.6 to 15 */
            background: linear-gradient(#eaeaea, #f8f8f8); /* Standard syntax */
            padding: 10px;
            height: 100%;

            p {
                font-size: 14px;
                padding: 15px;
            }

            span {
                font-size: 16px;
                color: $theme-color;
                font-weight: bold;
            }
        }

        .featured-dealer-right {
            h3 {
                color: #fff;
                background: $theme-color;
                width: 100%;
                font-size: 18px;
                font-weight: bold;
                padding: 10px 25px;
                margin-top: 0;
            }

            h4 {
                color: $theme-color;
                font-weight: bold;
                padding-left: 25px;
                font-size: 18px;
                margin-bottom: 5px;
            }

            p {
                padding-left: 25px;
                padding-right: 25px;
                margin-bottom: 20px;
            }

            ul {
                color: #555555;
                font-size: 16px;
                list-style: none;
                padding-left: 25px;
                margin-bottom: 15px;
            }

            li {
                margin-bottom: 5px;
                padding-left: 15px;
            }

            li:before {
                content: "\002022";
                padding-right: 5px;
                color: $theme-color;
                margin-left: -15px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .category-page {
        #mast {
            height: 150px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .featured-dealers {
        .featured-dealer-left {
            text-align: center;
        }
    }
}