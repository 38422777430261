.about-location {
	h3 {
		margin-top: 0;
        margin-bottom: 20px;
        border-top: 1px solid $theme-color;
        border-bottom: 1px solid $theme-color;
        color: $theme-color;
        font-weight: bold;
        font-size: 24px;
        padding: 10px;
	}
	p {
		margin-bottom: 20px;
		padding: 0 10px;
	}
	.theme-button {
		padding: 10px 75px 10px 25px;
		margin-right: 20px;
		font-size: 18px;
	}
}
.about-sidebar {
	background: -webkit-linear-gradient(#eaeaea, #f8f8f8); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(#eaeaea, #f8f8f8); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(#eaeaea, #f8f8f8); /* For Firefox 3.6 to 15 */
	background: linear-gradient(#eaeaea, #f8f8f8); /* Standard syntax */
	padding: 10px;
	height: 100%;

	h3 {
		margin: 0 0 15px 0;
	}
}

.location-contact-row {
	margin-bottom: 40px;
}

.henrietta {
	#header {
		#logo-bar {
			#header-stripe {
				background-color: $henrietta-theme-color;
			}
		}
	}

    h2.section-header {
    	color: $henrietta-theme-color;
    	border-top: 1px solid $henrietta-theme-color;
    	border-bottom: 1px solid $henrietta-theme-color;
    }

	.about-location {
		h3 {
	        border-top: 1px solid $henrietta-theme-color;
	        border-bottom: 1px solid $henrietta-theme-color;
	        color: $henrietta-theme-color;
		}
	}

	.theme-button {
		background: url(/img/green-div-arrow.png) top right no-repeat;
		background-size: contain;
		border: 1px solid $henrietta-theme-color;
		color: $henrietta-theme-color;
	}
	.red-button {
		background: $henrietta-theme-color;
	}
}

.ontario, .irondequoit {
	#header {
		#logo-bar {
			#header-stripe {
				background-color: $ontario-theme-color;
			}
		}
	}
	.about-location {
		h3 {
	        border-top: 1px solid $ontario-theme-color;
	        border-bottom: 1px solid $ontario-theme-color;
	        color: $ontario-theme-color;
		}
	}
	.theme-button {
		background: url(/img/blue-div-arrow.png) top right no-repeat;
		background-size: contain;
		border: 1px solid $ontario-theme-color;
		color: $ontario-theme-color;
	}
	.red-button {
		background: $ontario-theme-color;
	}
}