.directions-page {
    #mast {
        height: 360px;

        #map-canvas {
            height: 100%;
            margin: 0;
            padding: 0;
        }

        span {
            color: #555555;
            font-size: 12px;
        }

        .infowindow {
            p {
                font-size: 14px;
            }
            .pull-left {
                width: 25%;
            }
            .pull-right {
                width: 70%;
                text-align: left
            }
        }
    }

    .contact-container {

        .section-header {
            margin-bottom: 30px;
            border-top: 1px solid #9f792d;
            border-bottom: 1px solid #9f792d;
        }

        .salamanca {
            .contact-alt-right {
                h4 {
                    color: $theme-color;
                }
                .theme-button {
                    border: 1px solid $theme-color;
                    color: $theme-color;
                    background: url(/img/red-div-arrow.png) right center no-repeat;
                }
            }
        }
        .henrietta {
            .contact-alt-right {
                h4 {
                    color: $henrietta-theme-color;
                }
                .theme-button {
                    border: 1px solid $henrietta-theme-color;
                    color: $henrietta-theme-color;
                    background: url(/img/green-div-arrow.png) right center no-repeat;
                }
            }
        }
        .ontario, .irondequoit {
            .section-header {
                color: $ontario-theme-color;
            }
        }

        .contact-left {
            background: -webkit-linear-gradient(#eaeaea, #f8f8f8); /* For Safari 5.1 to 6.0 */
            background: -o-linear-gradient(#eaeaea, #f8f8f8); /* For Opera 11.1 to 12.0 */
            background: -moz-linear-gradient(#eaeaea, #f8f8f8); /* For Firefox 3.6 to 15 */
            background: linear-gradient(#eaeaea, #f8f8f8); /* Standard syntax */
            padding: 10px;
            height: 100%;

            .theme-button {
                font-size: 16px;
                height: 40px;
                padding-top: 8px;
            }

            p {
                font-size: 14px;
                padding: 15px;
            }

            span {
                font-size: 16px;
                color: $theme-color;
                font-weight: bold;
            }
        }

        .contact-alt-right {
            h3 {
                color: #fff;
                background: $alt-theme-color;
                width: 100%;
                font-size: 18px;
                font-weight: bold;
                padding: 10px 25px;
                margin-top: 0;
                margin-bottom: 0;
            }

            h4 {
                color: $alt-theme-color;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 5px;
            }

            p {
                margin-bottom: 20px;
            }

            .theme-button {
                padding: 15px 120px 15px 10px;
                border: 1px solid $alt-theme-color;
                color: $alt-theme-color;
                font-size: 16px;
                background: url('/img/blue-div-arrow.png') right center no-repeat;
            }


        }

        .location-contact-row {
            &.henrietta {
                .contact-left {
                    span {
                        color: $henrietta-theme-color;
                    }
                }
                .contact-right {
                    h3 {
                        background: $henrietta-theme-color;
                    }
                    h4, label {
                        color: $henrietta-theme-color;
                    }
                    .form-container {
                        border-image: linear-gradient(to bottom, $henrietta-theme-color, transparent) 1 100%;
                    }
                }
            }
            &.ontario, &.irondequoit {
                .contact-left {
                    span {
                        color: $ontario-theme-color;
                    }
                }
                .contact-right {
                    h3 {
                        background: $ontario-theme-color;
                    }
                    h4, label {
                        color: $ontario-theme-color;
                    }
                    .form-container {
                        border-image: linear-gradient(to bottom, $ontario-theme-color, transparent) 1 100%;
                    }
                }
            }
        }

        .contact-right {
            .form-container {
                border-width: 2px;
                border-style: solid;
                -webkit-border-image:
                -webkit-gradient(linear, 0 0, 0 100%, from($theme-color), to(rgba(0, 0, 0, 0))) 1 100%;
                -webkit-border-image:
                -webkit-linear-gradient($theme-color, rgba(0, 0, 0, 0)) 1 100%;
                -moz-border-image:
                -moz-linear-gradient($theme-color, rgba(0, 0, 0, 0)) 1 100%;
                -o-border-image:
                -o-linear-gradient($theme-color, rgba(0, 0, 0, 0)) 1 100%;
                border-image:
                linear-gradient(to bottom, $theme-color, rgba(0, 0, 0, 0)) 1 100%;

                border-left: 0;
            }

            form {
                padding-left: 25px;
                padding-right: 25px;

                label {
                    color: $theme-color;
                }

                .theme-button {
                    font-size: 16px;
                    height: 40px;
                    width: 230px;
                }

                .form-control {
                    border: 1px solid $theme-accent;
                }
            }

            h3 {
                color: #fff;
                background: $theme-color;
                width: 100%;
                font-size: 18px;
                font-weight: bold;
                padding: 10px 25px;
                margin-top: 0;
                margin-bottom: 0;
            }

            h4 {
                color: $theme-color;
                font-weight: bold;
                padding-left: 25px;
                font-size: 18px;
                margin-bottom: 5px;
            }

            p {
                padding-left: 25px;
                padding-right: 25px;
                margin-bottom: 20px;
            }

            ul {
                color: #555555;
                font-size: 16px;
                list-style: none;
                padding-left: 25px;
                margin-bottom: 15px;
            }

            li {
                margin-bottom: 5px;
            }

            li:before {
                content: "\002022";
                padding-right: 5px;
                color: $theme-color;
            }
        }

        .visit-our-locations-head h1 {
            color: $theme-color;
        }
    }
}

@media only screen and (max-width: 768px) {
    .directions-page {
        #mast {
            height: 150px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .contact-container {
        .contact-left {
            text-align: center;
        }
    }
}