.red {
  color: #a71d21;
}

.location-tabs a {
  color: #999999;
  font-size: 18px;
}

.location-tabs a:hover, .location-tabs a:focus {
    color: #a71d21;
}

.location-tabs > li.active > a, .location-tabs > li.active > a:hover, .location-tabs > li.active > a:focus {
  color: #a71d21;
}

.location h4.panel-group-title {
  color: #fff;
  background: #a71d21;
  padding: 15px;
  margin-top: 30px;
}

.location h4.panel-group-title small {
  color: #fff;
}

.location .panel-heading {
  cursor: pointer;
}

.location .panel-heading a {
  text-decoration: none;
}

.location .panel-body ul {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}

.location .panel-body li {
  margin-bottom: 15px;
}

.location .panel-heading .panel-title:after {
  content: "\025BE";
  float: right;
  color: #a71d21;
}

.location .panel-heading.collapsed .panel-title:after {
  content: "\025B8";
}

.location a:hover, .location a:focus {
  color: #a71d21;
}