.featured-dealers-page {
    #category-slider-container {
        margin-bottom: 50px;
        margin-top: 30px;
        width: auto;

        #category-slider {
            width: 90%;
            margin: 0 auto;

            .slick-slide {
                text-align: center;
                font-size: 16px;
                line-height: 1.1;
                font-weight: bold;
            }

            img {
                margin: 0 auto;
                padding-bottom: 10px;
            }

            .slick-prev {
                &:before {
                    content: "";
                }
                background: url('/img/left-arrow-red.png') top left no-repeat;
                width: 28px;
                height: 66px;
                margin-top: -60px;
                left: -35px;
            }

            .slick-next {
                &:before {
                    content: "";
                }
                background: url('/img/right-arrow-red.png') top left no-repeat;
                width: 28px;
                height: 66px;
                margin-top: -60px;
                right: -35px;
            }
        }
    }

    
    #featured-dealers {
        .row {
            margin-bottom: 50px;
        }

        .category-image {
            text-align: center;
            margin-bottom: 10px;

            img {
                border: 10px solid #eaeaea;
            }
        }

        .featured-dealer-info {
            color: #555555;
            font-size: 16px;

            p {
                padding-left: 10px;
                padding-right: 10px;

                span {
                    color: $theme-color;
                    font-weight: bold;
                    font-size: 24px;
                }

                img {
                    float: right;
                }
            }

            h2 {
                margin-top: 0;
                margin-bottom: 40px;
                border-top: 1px solid $theme-color;
                border-bottom: 1px solid $theme-color;
                color: $theme-color;
                font-weight: bold;
                font-size: 24px;
                padding: 10px;

                span {
                    display: inline-block;
                    font-size: 18px;
                    color: #555555;
                }
            }

            .theme-button {
                padding-top: 11px;
                height: 50px;
                padding-right: 60px;
                margin-top: 25px;
                font-size: 16px;

                span {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .featured-dealers-page {

    }
}