.history-page {

    .info-row {
        background: -webkit-linear-gradient(#eaeaea, #f8f8f8); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(#eaeaea, #f8f8f8); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(#eaeaea, #f8f8f8); /* For Firefox 3.6 to 15 */
        background: linear-gradient(#eaeaea, #f8f8f8); /* Standard syntax */

        border-bottom: 1px solid $theme-color;
    }

    .info-left {
        padding: 10px;
        height: 100%;
    }

    .info-right {
        background: white;
        p {
            padding: 15px;
        }
        h4{
            font-size: 18px;
            color: $theme-color;
            font-weight: bold;
            padding-left: 15px;
        }
        h3 {
            background: $theme-color;
            color: white;
            width: 100%;
            margin: 0;
            padding: 10px 20px;
            font-weight: bold;
            font-size: 22px;

            span {
                font-weight: normal;
                font-size: 16px;
            }
        }
    }
}