$theme-color: #a71d21;
$theme-accent: #9f792d;
$alt-theme-color: #2a2e7b;
$henrietta-theme-color: #164c43;
$ontario-theme-color: #2a2e7b;

// Update Bootstrap variables
$font-family-base:                "Lato", sans-serif;
$body-bg:                         #fff;
$brand-primary:                   $theme-color;
$navbar-default-color:            #fff;
$navbar-default-bg:               $theme-color;
$navbar-default-link-color:       #fff !default;
$navbar-default-link-hover-color: #333 !default;
$btn-default-color:               $theme-color !default;
$btn-default-bg:                  #fff !default;
$btn-default-border:              $theme-accent !default;

@import "bootstrap";

// Firefox fix for responsive tables
@-moz-document url-prefix() {
    fieldset {
        display: table-cell;
    }
}

html,
body {
    min-height: 100%;
    padding: 0;
    margin: 0;
}

p {
    font-size: 16px;
    color: #555555;
}

h2.bread-crumb {
    color: #555555;
    font-weight: bold;
    font-size: 18px;
    span {
        font-size: 30px;
        color: $theme-color;
    }
}

h2.section-header{
    width: 100%;
    color: $theme-color;
    border-top: 1px solid $theme-color;
    border-bottom: 1px solid $theme-color;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    padding: 10px 0;
    margin-bottom: 0;
}

h2.inside-header {
    width: 100%;
    color: $theme-color;
    border-top: 1px solid $theme-color;
    border-bottom: 1px solid $theme-color;
    text-align: center;
    font-size: 30px;
    padding: 15px 0;
    font-weight: bold;
    margin-bottom: 25px;

    span {
        font-size: 24px;
        color: #555555;
        font-weight: normal;
    }
}

.videoWrapper {
    margin: 10px;
    position: relative;
    padding-bottom: 55%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.before-footer {
    margin-bottom: 60px;
}

.img-auto {
    max-width: 100%;
    height: auto;
}

#header {
    position: relative;
    padding-top: 1px;

    #header-nav-container {
        height: 40px;
        border-bottom: 1px solid $theme-accent;
        width: 100%;

        #header-nav {
            max-width: 1140px;
            margin: 0 auto;
            position: relative;

            .pull-right {
                position: relative;
            }

            .header-nav-item:first-child {
                border-left: 1px solid #cfbc96;
            }
            .header-nav-item {
                position: relative;
                height: 40px;
                width: 165px;
                display: inline-block;
                text-align: center;
                z-index: 11;
                border-right: 1px solid #cfbc96;
                font-size: 16px;
                margin-left: -5px;
                padding-top: 8px;

                a {
                    color: $theme-color;
                }

                &.active {
                    background: $theme-color;
                    position: relative;
                    a {
                        color: #fff;
                    }

                    :after {
                        content: "";
                        display: block;
                        position: absolute;
                        background: url('/img/header-active-arrow.png');
                        width: 28px;
                        height: 12px;
                        margin-left: -14px;
                        left: 50%;
                        bottom: -13px;
                    }
                }
            }
        }
    }

    #logo-bar {
        position: absolute;
        top: 10px;
        height: 166px;
        width: 100%;
        z-index: 2;

        #header-stripe {
            width: 100%;
            position: absolute;
            top: 61px;
            height: 44px;
            background-color: $theme-color;
            border-top: 2px solid $theme-accent;
            border-bottom: 2px solid $theme-accent;
        }

        #header-logo {
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -130px;
            z-index: 10;
        }
    }
}

#mast {
    margin-bottom: 25px;
    background-size: cover !important;
    overflow: hidden;
    text-align: center;
    padding-top: 160px;
    color: #555555;
    font-size: 28px;
    line-height: 1.2;

    span {
        font-size: 38px;
        font-weight: bold;
        color: $theme-color;
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#main-nav {
    display: none;
}

.down-arrow {
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;
    padding-top: 2px;
    margin-bottom: 30px;
}

#mall-layout {
    margin-bottom: 20px;

    .badge {
        min-width: 20px;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.15);
        border-radius: 0;
        font-size: 10px;
        padding: 4px 4px;
    }

    .list-group {
        margin-bottom: 0;
    }

    .list-group-item {
        color: #fff;
        padding: 7px 15px;
        font-weight: bold;
        font-size: 12px;
        border-left: 0;
        border-right: 0;
        border-color: #fff;
        a {
            color: #fff;
        }
    }

    .list-group-item:first-child {
        border-top: 0;
        border-radius: 0;
    }

    .list-group-item:last-child {
        border-bottom: 0;
        border-radius: 0;
    }

    #mall-layout-display {
        background: #eaeaea;
        width: 100%;
        height: 100%;
    }

    #mall-layout-categories {
        width: 30%;
    }

    #mall-layout-map {
        width: 70%;
        padding: 20px 10px;

        img {
            margin: auto;
        }
    }

    .painting-category{
        background-color: #6c521f;
    }
    .jewelery-category {
        background-color: #9f792d;
    }
    .furniture-category {
        background-color: #af9766;
    }
    .clock-category {
        background-color: #503f17;
    }
    .military-category {
        background-color: #6b5d3f;
    }
    .asian-category {
        background-color: #6b521f;
    }
    .vintage-category {
        background-color: #84724e;
    }
    .books-category {
        background-color: #b78d35;
    }
    .tools-category {
        background-color: #372b10;
    }
    .pre20th-category {
        background-color: #836726;
    }
}

#main-footer {
    border-top: 10px solid $theme-accent;
    padding-top: 12px;

    a {
        color: $theme-color;
    }

    .list-inline > li:last-child {
        border-right: 0;
    }

    .list-inline > li {
        border-right: 1px solid #d2c09d;
        padding: 0 15px;
        font-size: 16px;
    }

    #footer-bottom {
        background: #f6f6f6;
        border-top: 1px solid $theme-accent;
        padding-bottom: 25px;
        padding-top: 38px;
        line-height: 1.3;
    }

    #footer-copy {
        font-size: 11px;
        color: #555555;
        padding-bottom: 15px;
    }

    #footer-info {
        padding-bottom: 15px;
    }

    #social-links {
        padding-bottom: 15px;
        padding-right: 0;
    }

    .social-link {
        margin: 0 15px;
    }

    #footer-newsletter {
        background: url("/img/footer-newsletter.png") top left no-repeat;
        width: 339px;
        margin: 0 auto;
        text-align: left;

        p {
            padding: 15px 20px 0 85px;
        }

        form {
            position: relative;
            padding-top: 10px;
            margin-left: 80px;

            input[type='text'] {
                border: 1px solid $theme-accent;
                border-radius: 5px;
                width: 110px;
                padding: 5px 10px;
                margin-right: 10px;
            }

            a {
                width: 115px;
                height: 30px;
                position: absolute;
                bottom: -45px;
                right: 25px;
                background: $theme-color;
                color: #fff;
                border: 0;
                border-radius: 5px;
                padding-top: 5px;
                padding-left: 10px;
            }

            input[type='submit'] {
                width: 115px;
                height: 30px;
                position: absolute;
                bottom: -45px;
                right: 25px;
                background: $theme-color;
                color: #fff;
                border: 0;
                border-radius: 5px;
            }
        }
    }
}

.red-button {
    display: inline-block;
    background: $theme-color;
    color: #fff;
    border-radius: 4px;
    padding: 4px 15px;
    width: auto;
}

.blue-button {
    display: inline-block;
    background: $alt-theme-color;
    color: #fff;
    border-radius: 4px;
    padding: 4px 15px;
    width: auto;
}

.no-gutter {
    padding-left: 0;
    padding-right: 0;
}

p.inside-content {
    font-size: 16px;
    color: #555555;
    padding-left: 10px;
}

.theme-button {
    &.inside {
        height: 50px;
        padding-top: 6px;
    }

    display: inline-block;
    padding-left: 25px;
    padding-right: 15%;
    border: 1px solid $theme-color;
    border-radius: 5px;
    font-size: 24px;
    color: $theme-color;
    background: url("/img/red-div-arrow.png") top right no-repeat;
    background-size: contain;
}

.testimonial-container {
    height: auto;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 20px;
}

.testimonial-quote-container {

    &:before,
    &:after {
        color: $theme-color;
        font-size: 140px;
        font-family: Lustria, sans-serif;
        height: 60px;
        width: 52px;
        float: left;
        line-height: 1;
    }

    &:before { content: "\201C"; }
    &:after {
        content: "\201D";
    }

    .testimonial {
        max-width: calc(100% - 105px);
        float: left;
        padding-left: 5px;
        font-style: italic;
        font-size: 24px;
        color: #555555;

        .testimonial-info {
            margin-top: 10px;
            text-align: right;
            margin-right: -50px;
            font-size: 14px;

            img {
                width: 65px;
                height: 65px;
                margin-right: 10px;
            }

            span {
                font-size: 16px;
            }
        }
    }
}

.category-nav {
    border-top: 1px solid $theme-accent;
    border-bottom: 1px solid $theme-accent;
    margin-bottom: 35px;

    ul {
        margin: 0;

        li:first-child {
            border-left: 1px solid $theme-accent;
        }
        li {
            margin-left: -5px;
            font-weight: bold;
            padding-top: 11px;
            padding-left: 9px;
            padding-right: 9px;
            height: 42px;
            font-size: 13px;
            border-right: 1px solid $theme-accent;
        }
    }
}

@media only screen and (max-width: 768px) {
    #mast {
        padding-top: 25px;
    }
    #mall-layout {
        #mall-layout-categories {
            width: 100%;
        }

        #mall-layout-map {
            width: 100%;
        }
    }

}

@media only screen and (max-width: 992px) {
    #header {
        #header-nav-container {
            #header-nav {
                .header-nav-item {
                    width: 170px;
                }
            }
        }
    }

    #header {
        display: none;
    }

    #main-nav {
        display: block;
        margin-bottom: 0;
    }

    #main-footer {
        .list-inline {
            text-align: center;
        }

        #footer-bottom {
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1200px) {
    #header {
        #logo-bar {
            top: 55px;
        }
        #header-nav-container {
            #header-nav {
                .header-nav-item {
                    width: 165px;
                }
            }
        }
    }
    .category-nav {
        ul {
            li:first-child {
                border: 0;
            }
            li {
                border: 0;
            }
        }
    }

    #main-footer {
        #social-links {
            padding-top: 0;
        }
    }
}

@import "pages/home";
@import "pages/featured-dealers";
@import "pages/category";
@import "pages/directions";
@import "pages/day-trips";
@import "pages/gift-certificates";
@import "pages/blog";
@import "pages/history";
@import "pages/landing";
@import "pages/special-offer";
@import "pages/antiquing-day-trips";