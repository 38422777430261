.special-offer-page {
	.before-footer {
		.section-header {
			margin-bottom: 20px;
		}
		form {
			input {
				width: 100%;
				padding: 10px 5px;
				margin-bottom: 10px;
			}
			input[type="submit"] {
			    background: #a71d21;
			    color: #fff;
			    border: 0;
			    border-radius: 5px;
			}
		}
	}
}