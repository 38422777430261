.home {
    #mast {
        height: 560px;
        overflow: hidden;
        text-align: center;
        background: url(/img/home/home-header-bg.jpg) center top no-repeat;
        padding-top: 200px;
        padding-bottom: 15px;
        color: #fff;
        font-family: Merriweather, serif;
        font-size: 30px;
        font-weight: 900;
        text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
        border-bottom: 1px solid $theme-accent;

        h1 {
            font-size: 66px;
            font-weight: 900;
        }

        .btn-default {
            font-size: 18px;
            padding: 10px 16px;
        }
    }

    #featured-week-item {
        margin-top: 20px;
        border: 10px solid #eaeaea;
        min-height: 100px;
        position: relative;

        #featured-item {
            zoom: 2;
            max-width: 100%;
            max-height: 100%;
            height: auto;
        }

        #featured-week-item-banner {
            color: #fff;
            font-weight: bold;
            height: 50px;
            width: 100%;
            background-color: $theme-color;
            border-top: 2px solid $theme-accent;
            border-bottom: 2px solid $theme-accent;
            text-align: center;
            padding-top: 5px;
            font-size: 24px;
            position: absolute;
            top: 50%;
            margin-top: -25px;

            .btn-default {
                padding: 2px 10px;
                margin-left: 6px;
                font-size: 16px;
            }
        }
    }

    #special-offers {
        h2 {
            text-align: left;
            padding-left: 10px;
        }

        p {
            padding: 10px;
        }
    }

    #category-slider-container {
        margin-bottom: 50px;
        margin-top: 30px;
        width: auto;

        #category-slider {
            width: 90%;
            margin: 0 auto;

            .slick-slide {
                text-align: center;
                font-size: 16px;
                line-height: 1.1;
                font-weight: bold;
            }

            img {
                margin: 0 auto;
                padding-bottom: 10px;
            }

            .slick-prev {
                &:before {
                    content: "";
                }
                background: url('/img/left-arrow-red.png') top left no-repeat;
                width: 28px;
                height: 66px;
                margin-top: -60px;
                left: -35px;
            }

            .slick-next {
                &:before {
                    content: "";
                }
                background: url('/img/right-arrow-red.png') top left no-repeat;
                width: 28px;
                height: 66px;
                margin-top: -60px;
                right: -35px;
            }
        }
    }

    #latest-news {
        margin-bottom: 25px;

        h2 {
            text-align: left;
            padding-left: 10px;
        }

        article {
            margin-top: 35px;
            padding-left: 10px;
            header {
                margin-bottom: 10px;
                h3 {
                    color: $theme-color;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: -3px;
                }

                time {
                    font-size: 12px;
                    color: #555555;
                }
            }

            p {
                color: #555555;
                font-size: 16px;
                line-height: 1.2;
            }
        }
    }

    .theme-button {
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
        width: 360px;
        padding-left: 45px;
        padding-top: 20px;
        height: 80px;
    }
}

@media only screen and (max-width: 992px) {
    .home {
        #special-offers {
            h2 {
                text-align: center;
                padding-left: 0;
            }
        }

        #latest-news {
            h2 {
                text-align: center;
                padding-left: 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .home {
        #mast {
            height: auto;
            font-size: 20px;
            padding-top: 0;

            h1 {
                font-size: 40px;
            }
        }
    }
}

@media only screen and (max-width: 725px) {
    .home {
        #mast {
            font-size: 16px;

            h1 {
                font-size: 25px;
            }

            .button {
                font-size: 14px;
            }
        }
    }
}
