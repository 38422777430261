.day-trips-page {
    #mast {
        background: url('/img/day-trips-bg.jpg') top center no-repeat;
        height: 360px;
    }
}

@media only screen and (max-width: 768px) {
    .day-trips-page {
        #mast {
            height: 150px;
        }
    }
}